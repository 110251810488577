<template>
	<div class="notice">
		<div style="display: flex;justify-content: space-between;">
			<div class="header">
				<div>{{columntitle}}></div>
				<div class="current">{{ title }}</div>
			</div>
		</div>

		<div class="column">
			<div v-for="(item,index) in threeLevel" :key="index" v-if="index < 3">
				<div class="name" @click="column(index + 1,item.id,item)"
					:style="{'background':columnIndex == index + 1 ? '#164B92':'#ECF1FD','color':columnIndex == index + 1 ? '#FFFFFF':'#3C75C2' }">
					{{item.name}}
				</div>
			</div>
		</div>

		<div class="column columnx">
			<div class="triangle" v-for="(item,index) in threeLevel" :key="index"
				:style="{'visibility': columnIndex != index + 1 ? 'hidden':''}">
				<img src="../assets/image/triangle2.png" alt="">
			</div>
		</div>

		<div class="projectList">
			<div class="title" @click="getsports('','')" :style="{'color':sportsindex == '' ? '#3C75C2':'#000' }">全部
			</div>
			<div class="title" v-for="(item,index) in sportsList" :key="index" @click="getsports(index + 1,item)"
				:style="{'color':sportsindex == index + 1 ? '#3C75C2':'#000' }">{{item.name}}</div>
		</div>

		<div class="horizontalline" v-if="date && date.length > 0"></div>

		<div class="content">
			<div class="specific">
				<ul>
					<li v-for="(item,index) in date" :key="index" v-if="template == 'date_list'">
						<div @click="jump(item.id)" style="height: 72PX;">
							<div class="news-date">
								<b>{{item.publishTime.split(" ")[0].split("-")[2]}}</b>
								<p>{{item.publishTime.split(" ")[0].split("-")[0]}}-{{item.publishTime.split(" ")[0].split("-")[1]}}
								</p>
							</div>
							<h2>
								{{item.title}}
							</h2>
							<p class="news-content">
								{{getText(item.content)}}
							</p>
						</div>
						<div class="horizontalline horizon"
							v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
					</li>
					<div v-if="template == 'picture'" style="display: flex;flex-wrap: wrap;">
						<div class="news_box" v-for="(item,index) in date" :key="index" v-if="template == 'picture'">
							<div class="news_item">
								<a @click="jump(item.id)">
									<img :src="item.imgUrl" v-if="item.imgUrl"/>
									<img src="../assets/image/placeholder.png" alt="" v-else>
									<h3>
										<div class="pic-title">
											{{item.title}}
										</div>
									</h3>
								</a>
							</div>
							<!-- <div class="horizontalline horizon" v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div> -->
						</div>
					</div>
					<li v-for="(item, index) in date" :key="index" class="nli" @click="jump(item.id)"
						v-if="template == 'picture_list'">
						<div>
							<div class="img" v-if="item.imgUrl">
								<img :src="item.imgUrl" alt="">
							</div>
							<div class="img" v-else>
								<img src="../assets/image/placeholder.png" alt="">
							</div>
							<h2 class="title2">
								{{item.title}}
							</h2>
							<div class="img_content">
								{{getText(item.content)}}
							</div>
							<span class="date">
								{{item.createTime.split(" ")[0]}}
							</span>
						</div>
						<div style="margin-top: 43PX" class="horizontalline horizon"
							v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
					</li>
					<li v-for="(item,index) in date" :key="index" @click="jump(item.id)"
						v-if="template == 'common_list'">
							
						<div style="display: flex;justify-content: space-between;">
							<div class="rounddot">
								<div class="round"></div>
								<a class="roundtitle">
									{{item.title}}
								</a>
							</div>
							<span>[{{item.publishTime | format}}]</span>
						</div>
						<div class="horizontalline horizon"
							v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
					</li>
					<div v-if="template == 'picture_column'" style="display: flex;flex-wrap: wrap;">
						<div class="news_box" v-for="(item,index) in date" :key="index" v-if="template == 'picture_column'">
							<div class="news_item">
								<a @click="jump(item.id)">
									<img :src="item.imgUrl" v-if="item.imgUrl"/>
									<img src="../assets/image/placeholder.png" alt="" v-else>
									<h3>
										<div class="pic-title">
											{{item.title}}
										</div>
									</h3>
								</a>
							</div>
							<!-- <div class="horizontalline horizon" v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div> -->
						</div>
					</div>
					<li v-for="(item,index) in date" :key="index"
						v-if="template != 'picture_column' && template != 'common_list'&& template != 'picture_list'&& template != 'picture'&& template != 'date_list'">
						<div style="display: flex;justify-content: space-between;">
							<div class="rounddot">
								<div class="round"></div>
								<a class="roundtitle" @click="jump(item.id)">
									{{item.title}}
								</a>
							</div>
							<span>[{{item.publishTime | format}}]</span>
						</div>
						<div class="horizontalline horizon"
							v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
					</li>
				</ul>
			</div>
			<div class="horizontalline" v-if="date && date.length > 0 && !Number.isInteger(Number(date.length) / 5)">
			</div>
		</div>
		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="go" :pageSize="pageSize" :pageNo="pageNum"></pagination>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Meta from 'vue-meta'
	Vue.use(Meta)
	export default {
		name: '',
		components: {},
		data() {
			return {
				id: '',
				pageNum: 1,
				pageSize: 20,
				total: 0,
				date: [],
				template: '',
				columntitle:'',
				title: '',
				sportsList: [],
				sportsindex: '',
				columnIndex: 1,
				threeLevel: [],
				go:20
			}
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		created() {
			this.findAllColumn()
		},
		methods: {
			getRefereeSports(id) {
				this.$api.getRefereeSports({
					dictId: id
				}).then(res => {
					this.sportsList = res.data.data.result
				})
			},
			column(e, id,item) {
				this.columnIndex = e
				this.sportsindex = 0
				this.findLabelList(id)
				this.template = item.template
				if(item.template == 'picture' || this.template == 'picture_column'){
					this.pageSize = 21,
					this.go = 21
				}else{
					this.pageSize = 20,
					this.go = 20
				}
			},
			findAllColumn() {
				let data = {
					id: this.$route.query.id,
					pid: this.$route.query.pid
				}
				this.$api.findAllColumn(data).then(res => {
					this.columntitle = res.data.data.list[0].name
					this.title = res.data.data.pid.name
					
					document.title = res.data.data.pid.name + '-北京市体育竞赛管理和国际交流中心'
					this.$meta().refresh()
					
					for (let i in res.data.data.list[0].children) {
						if (res.data.data.list[0].children[i].id == this.$route.query.id) {
							this.threeLevel = res.data.data.list[0].children[i].children
							this.id = res.data.data.list[0].children[i].children[0].id
							this.template =  res.data.data.list[0].children[i].children[0].template
							
							if(this.template == 'picture' || this.template == 'picture_column'){
								this.pageSize = 21,
								this.go = 21
							}else{
								this.pageSize = 20,
								this.go = 20
							}
						}
					}
					this.findLabelList(this.id)
				})
			},
			currentchange(val) {
				this.pageNum = val
				this.getArticleListById(this.id,'')
			},
			getArticleListById(id, labelId) {
				let data = {
					id: id,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
					labelId: labelId || null,
				}
				this.$api.getArticleListById(data).then(res => {
					this.total = res.data.data.total
					this.date = res.data.data.list
					this.date.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/articleDetails',
					query: {
						id: id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
			getText(str) {
				let hasChineseFontFamily = /font-family:\s*[^;]*[\u4E00-\u9FFF]+/g.test(str)
				if (hasChineseFontFamily) {
				  str = str.replace(/font-family:\s*[^;]*[\u4E00-\u9FFF]+/g, "font-family: ''");
				}
				let res = str.replace(/[^\u4e00-\u9fa5]/gi, "").replace("微软雅黑", "")
				if (res.length > 80) {
					res = res.slice(0, 80) + '...'
				}
				return res
			},
			findLabelList(id,item) {
				let data = {
					columnIds: [id]
				}
				this.$api.findLabelList(data).then(res => {
					this.sportsList = res.data.data
				})
				this.pageNum = 1
				this.getArticleListById(id, '')
				this.id = id
			},
			getsports(index, item) {
				this.pageNum = 1
				this.sportsindex = index
				this.getArticleListById(this.id, item.id || '')
			},
		}
	}
</script>

<style lang="scss" scoped>
	.notice {
		width: 1200PX;
		margin: 0 auto;
	}

	.header {
		display: flex;
		margin-top: 34PX;
		margin-left: 20PX;
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		cursor: pointer;
	}

	.current {
		color: #0066FF;
	}

	.input {
		margin-top: 28PX;
		height: 39PX;
	}

	.input ::v-deep .el-input__inner {
		height: 34PX;
		border: 2PX solid #164B92;
		height: 39PX;
		width: 160PX;
	}

	.input ::v-deep .el-input-group__append {
		border: 2PX solid #164B92;
		color: #fff;
		background-color: #164B92;
		width: 10PX;
		height: 39PX;
	}

	.input ::v-deep .el-input-group__append button.el-button {
		font-weight: bold;
	}

	.buttom {
		display: flex;
		width: 149PX;
		height: 39PX;
		background: #D42C26;
		border-radius: 7PX;
		font-size: 15PX;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 39PX;
		margin-left: 30PX;
		margin-top: 28PX;
	}

	.buttom .img {
		width: 21PX;
		height: 21PX;
		margin-top: 10PX;
		margin-left: 28PX;
		margin-right: 9PX;
	}

	.screen-box {
		margin-top: 26PX;
		font-size: 18PX;
		font-weight: 400;
		margin-left: 20PX;
		color: #333333;
		display: flex;
	}

	.screen-box .screen {
		display: flex;
	}

	.screen-box .screen .title {
		margin-left: 20PX;
	}

	.themoon-box {
		width: 1200PX;
		height: 41PX;
		background: rgba(212, 212, 212, 0.15);
		border-radius: 12PX;
		margin-top: 20PX;
		display: flex;
		align-items: center;
	}

	.themoon-box .title {
		font-size: 16PX;
		font-weight: bold;
		color: #333333;
		margin-left: 34PX;
		cursor: pointer;
		padding: 0 20PX;
	}

	.themoon-box .name {
		font-size: 16PX;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		padding: 0 20PX;
	}

	.selected {
		color: #164B92 !important;
		height: 41PX;
		padding: 0 20PX;
		background: #ECF1FD;
		border-radius: 6PX;
		display: flex;
		align-items: center;
	}

	.column {
		margin-top: 30PX;
		display: flex;
		justify-content: center;
		border-bottom: 2PX solid #164B92;
		cursor: pointer;
	}

	.columnx {
		border: none !important;
		margin-top: 0 !important;
	}

	.column .name {
		width: 317PX;
		height: 50PX;
		background: #164B92;
		border-radius: 20PX 20PX 0PX 0PX;
		font-size: 20PX;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		line-height: 50PX;
		margin-right: 13PX;
	}

	.triangle {
		width: 317PX;
		margin-right: 13PX;
		text-align: center;
		margin-top: 10PX;
	}

	.triangle img {
		height: 40PX;
		height: 40PX;
	}

	.projectList {
		display: flex;
		background: #F0F0F0;
		flex-wrap: wrap;
		justify-content: flex-start;
		border-radius: 7PX;
		margin-top: -14PX;
		margin-bottom: 30PX;
		padding: 20PX 0 0 0;
	}

	.projectList .title {
		font-size: 16PX;
		font-weight: 400;
		color: #333333;
		margin: 0 30PX 20PX 30PX;
		cursor: pointer;
	}

	.paging {
		margin-top: 18PX;
		margin-bottom: 104PX;
		display: flex;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}

	.horizontalline {
		border: 1PX dashed #BFBFBF;
		margin-bottom: 23PX;
	}

	.content {
		width: 1200PX;
		margin: 0 auto;
	}

	.specific {
		font-size: 15PX;
		color: #333333;
		cursor: pointer;
	}

	.specific ul li {

		margin-bottom: 23PX;
	}

	.rounddot {
		display: flex;
		align-items: center
	}

	.round {
		width: 7PX;
		height: 7PX;
		border-radius: 100%;
		background: #000;
		box-shadow: 0 2PX 4PX 0 rgba(0, 0, 0, .2);
		margin-right: 10PX;
	}

	.roundtitle {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		width: 1000PX;
		cursor: pointer;
	}

	.paging {
		margin-top: 81PX;
		margin-bottom: 132PX;
		display: flex;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}

	::v-deep .el-pager li.active {
		color: #003399;
	}

	.news-date {
		width: 72PX;
		height: 72PX;
		border: 1PX solid #bfd4e9;
		float: left;
		margin-right: 30PX;
		font-family: arial;
		color: #6198e2;
		background: #fff;
		text-align: center;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	}

	.news-date b {
		height: 43PX;
		line-height: 42PX;
		font-size: 26PX;
	}

	.news-date p {
		font-size: 14PX;
		margin: 0 5PX;
		height: 26PX;
		line-height: 26PX;
		color: #6198e2;
		background: #fff;
		border-top: 1PX solid #dfe7f3;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	}

	.specific li h2 {
		overflow: hidden;
		font-size: 16PX;
		color: #333;
		font-weight: 700;
		height: 22PX;
		line-height: 22PX;
		margin-bottom: 8PX;
		margin-top: 0;
	}

	.specific li:hover a {
		color: #408ED6;
	}

	.specific li:hover .news-date {
		border: 1PX solid #408ED6;
		background: #408ED6;
		color: #fff;
	}

	.specific li:hover .news-date p {
		color: #fff;
		background: #408ED6;
	}

	.news_item {
		width: 380PX;
		margin-top: 28PX;
		margin-right: 30PX;
	}
	.news_box:nth-child(3n) .news_item{
		margin-right: 0;
	}
	
	.news_item img {
		width: 100%;
		height: 229PX;
	}

	.news_item h3 {
		font-size: 14PX;
		height: 54PX;
		line-height: 54PX;
		font-weight: 400;
		text-align: center;
		overflow: hidden;
	}

	.news_item:nth-child(3n) {
		margin-right: 0;
	}

	a:hover {
		color: #408ED6;
	}

	.nli {
		margin: 30PX 18PX 0;
		overflow: hidden;
		padding-bottom: 30PX;
		display: block !important;
	}

	.img {
		width: 200PX;
		height: 133PX;
		float: left;
		margin-right: 30PX;
		background: #fff;
	}

	.img img {
		margin: 0 auto;
		width: 200PX;
		height: 133PX;
		border: 0;
		display: block;
	}

	.title2 {
		height: 30PX;
		line-height: 30PX;
		overflow: hidden;
		font-size: 16PX;
		color: #333;
		font-weight: 700;
		margin-bottom: 10PX;
		margin-top: 5PX;
	}

	.date {
		color: #999;
		display: block;
		padding: 10PX 0 0;
	}

	.img_content {
		height: 40PX;
		overflow: hidden;
	}

	ul {
		margin-bottom: 30PX;
	}

	.news-text li:hover h2 {
		color: #408ED6;
	}

	h2 {
		cursor: pointer;
	}

	.pic_item {
		width: 33.3%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 20PX;
	}

	.item-box {
		width: 317PX;
		height: 257PX;
	}

	.pic_title {
		text-align: center;
		margin-top: 20PX;
		cursor: pointer;
		font-size: 16PX;
	}

	.horizon {
		border: 1PX dashed #BFBFBF;
		margin-bottom: 23PX;
		margin-top: 23PX;
		padding: 0 53PX;
		margin-left: 0;
	}
</style>